import React from "react";
import "./banner.css";

function Banner({ menuDisplay, openModal }) {
  console.log(menuDisplay);
  return (
    <>
      <div className="banner">
        <div
          onClick={openModal}
          className={`estimate ${menuDisplay ? "estimate-effect" : ""} `}
        >
          <i class="fa-solid fa-headset fa-shake icon-fa"></i>
          <span> GET A FREE ESTIMATE ?</span>
        </div>
      </div>
    </>
  );
}

export default Banner;
