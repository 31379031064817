import React, { useState } from "react";
// import Navbar from "./components/Navbar";
import "./App.css"; // Import your custom CSS file
import Banner from "./components/Banner";
import somany1 from "./assets/somany1.jpg";
import somany2 from "./assets/somany2.jpg";
import somany3 from "./assets/somany3.jpg";
import somany4 from "./assets/somany4.jpg";
import logo from "./assets/logo.png";
import SocialMediaReview from "./components/SocialMediaReview";
import Navbar from "./components/Navbar";
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import FormPopUp from "./components/FormPopUp";
import Products from "./components/Products";

function App() {
  const [menuDisplay, setMenuDisplay] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      {/* <Navbar /> */}

      <Navbar setMenuDisplay={setMenuDisplay} menuDisplay={menuDisplay} />
      <Menu menuDisplay={menuDisplay} />
      <Banner menuDisplay={menuDisplay} openModal={openModal} />
      <SocialMediaReview />
      <Products />

      <FormPopUp isOpen={isModalOpen} onClose={closeModal} />

      <Footer />
    </div>
  );
}

export default App;
