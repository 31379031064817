import { useRef, useState } from "react";
import facebook_review from "../assets/social_media_review/rating-facebook.png";
import google_review from "../assets/social_media_review/rating-google.png";
import yelp_review from "../assets/social_media_review/rating-yelp.png";
import "./social_media-reviews.css";
function SocialMediaReview() {
  const reviews = [google_review, facebook_review, yelp_review];

  return (
    <div className="container1">
      <div className="review-container">
        <img src={facebook_review} className="review" alt="facebook_review" />
      </div>
      <div className="review-container">
        <img src={google_review} className="review" alt="google_review" />
      </div>
      <div className="review-container">
        <img src={yelp_review} className="review" alt="yelp_review" />
      </div>
    </div>
  );
}

export default SocialMediaReview;
