import React from "react";
import "./formPopUp.css";

const FormPopUp = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div
      className="modal-overlay"
      onClick={(e) => {
        onClose();
      }}
    >
      <div
        className="modal"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal-header">
          <span
            className="modal-close"
            onClick={(e) => {
              e.stopPropagation();

              onClose();
            }}
          >
            &times;
          </span>
        </div>
        <div className="modal-content">
          <center>
            {" "}
            <h1>GET A FREE ESTIMATE</h1>
          </center>
          <div>
            <label htmlFor="">Name</label>
            <input className="input-field" type="text" />
          </div>
          <div>
            <label htmlFor="">Email</label>
            <input className="input-field" type="text" />
          </div>
          <div>
            <label htmlFor="">Contact</label>
            <input className="input-field" type="text" />
          </div>
          <div>
            <label htmlFor="">Query</label>
            <input className="input-field" type="text" />
          </div>
          <div className="submit" onClick={onClose}>
            <center>Submit</center>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormPopUp;
