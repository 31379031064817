import "./menu.css";
function Menu({ menuDisplay }) {
  return (
    <div className="xx">
      <ul
        className={`menu-container ${menuDisplay ? "yy" : "menuDisplay"}   ${
          !menuDisplay ? "affect-menu" : ""
        }`}
      >
        <li className="menu-item">Menu</li>
        <li className="menu-item">About</li>
        <li className="menu-item">Products</li>
        <li className="menu-item">Blog</li>
        <li className="menu-item">Contact Us</li>
      </ul>
    </div>
  );
}

export default Menu;
