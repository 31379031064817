import React from "react";
import "./products.css";
import a1 from "../assets/a1.jpg";
import a2 from "../assets/a2.jpg";
import a3 from "../assets/a3.jpg";
import a4 from "../assets/a4.jpg";
import a5 from "../assets/a5.jpg";
import a6 from "../assets/a6.jpg";
import a7 from "../assets/a7.jpg";
import a8 from "../assets/a8.jpg";
import a9 from "../assets/a9.jpg";
import a10 from "../assets/a10.jpg";
import a11 from "../assets/a11.jpg";
import a12 from "../assets/a12.jpg";
import a13 from "../assets/a13.jpg";
import a14 from "../assets/a14.jpg";
import a15 from "../assets/a15.jpg";
import a16 from "../assets/a16.jpg";
import a17 from "../assets/a17.jpg";
import a18 from "../assets/a18.jpg";
import a19 from "../assets/a19.jpg";
import a20 from "../assets/a20.jpg";
function Products() {
  const products = [
    a1,
    a2,
    a3,
    a4,
    a5,
    a5,
    a6,
    a7,
    a8,
    a9,
    a10,
    a11,
    a12,
    a13,
    a14,
    a15,
    a16,
    a17,
    a18,
    a19,
    a20,
  ];
  return (
    <div className="products-container">
      <center>
        <h1>PRODUCTS</h1>
      </center>
      <div className="products-wrapper">
        {products.map((i, index) => {
          return (
            <div key={index} className="product">
              <img src={i} className="product-img" alt="" />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Products;
//

// import React from "react";
// import "./products.css";
// import a1 from "../assets/a1.jpg";
// import a2 from "../assets/a2.jpg";
// import a3 from "../assets/a3.jpg";
// import a4 from "../assets/a4.jpg";
// import a5 from "../assets/a5.jpg";
// import a6 from "../assets/a6.jpg";
// import a7 from "../assets/a7.jpg";
// import a8 from "../assets/a8.jpg";
// import a9 from "../assets/a9.jpg";
// import a10 from "../assets/a10.jpg";
// import a11 from "../assets/a11.jpg";
// import a12 from "../assets/a12.jpg";
// import a13 from "../assets/a13.jpg";
// import a14 from "../assets/a14.jpg";
// import a15 from "../assets/a15.jpg";
// import a16 from "../assets/a16.jpg";
// import a17 from "../assets/a17.jpg";
// import a18 from "../assets/a18.jpg";
// import a19 from "../assets/a19.jpg";
// import a20 from "../assets/a20.jpg";
// function Products() {
//   const products = [
//     a1,
//     a2,
//     a3,
//     a4,
//     a5,
//     a5,
//     a6,
//     a7,
//     a8,
//     a9,
//     a10,
//     a11,
//     a12,
//     a13,
//     a14,
//     a15,
//     a16,
//     a17,
//     a18,
//     a19,
//     a20,
//   ];
//   return (
//     <div className="products-container">
//       <center>
//         <h1>PRODUCTS</h1>
//       </center>
//       <div className="products-wrapper">
//         <div className="product">
//           <img src={a1} className="product-img" alt="" />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Products;
