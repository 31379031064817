import "./navbar.css";
import logo from "../assets/logo2.png";
import { IoCall, IoMenu } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
import { useState } from "react";

function Navbar({ setMenuDisplay, menuDisplay }) {
  // const [menuButton, setMenuButton] = useState(false);
  // function menuButtonHandle() {
  //   setMenuButton(!menuButton);
  //   console.log(menuButton);
  // }
  function menuButtonHandle() {
    setMenuDisplay(!menuDisplay);
  }
  return (
    <div className=" ">
      <div className="nav-container">
        <div className="logo-container">
          <img src={logo} alt="sasds" className="nav-logo" />
        </div>

        {/* Navigation Links */}
        <ul className="nav-links">
          <li>
            <a href="#">Home</a>
          </li>
          <li>
            <a href="#">About</a>
          </li>
          <li>
            <a href="#">Services</a>
          </li>
          <li>
            <a href="#">Contact</a>
          </li>
        </ul>

        {/* Call-to-Action Button */}
        <button className="cta-button">
          <div>
            <IoCall className="icon-call" />
            &nbsp;
          </div>
          <div>CALL US : XXX-XXXX-XXXX</div>
        </button>

        <div className="menu-button-container" onClick={menuButtonHandle}>
          {menuDisplay ? (
            <IoClose className="menu-button" />
          ) : (
            <IoMenu className="menu-button" />
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
